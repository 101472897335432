import React from "react";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box } from "../../components/Core";
import IconCard from "../../components/Flop/IconCard";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import flopPmLogoDarkSvg from "../../assets/image/flop/flop-pm-logo-dark.png";
import flopAppLogoDarkSvg from "../../assets/image/flop/flop-app-logo-dark.png";
import flopGtoLogoDarkSvg from "../../assets/image/flop/flop-gto-logo-dark.png";
import PAPCLogoPng from "../../assets/image/flop/PAPC_logo_green.png";

const Products = () => {
  return (
    <Section>
      <Box px={[1, 1, 1, 5, 7]}>
        <Row className="align-items-center justify-content-center mb-5">
          <Col lg="6" className="text-center">
            <Title className="mb-5">
              <FlopFormattedMessageWrapper id="about.products.title" />
            </Title>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col lg="4" md="6" className="text-center mb-4">
            <IconCard
              image={flopAppLogoDarkSvg}
              imageLink="/flop-app"
              desc={'Matching Live Poker Players with active games and helping them to secure their seats'}
            />
          </Col>
          <Col lg="4" md="6" className="text-center mb-4">
            <IconCard
              image={flopGtoLogoDarkSvg}
              imageLink="/flop-pm"
              desc={'Learn and improve your game with tailored GTO solutions for cash games, tournaments, spins and heads-up play'}
            />
          </Col>
          <Col lg="4" md="6" className="text-center mb-4">
            <IconCard
              image={flopPmLogoDarkSvg}
              imageLink="/flop-pm"
              desc={'A Poker Management Web Application that simplifies operations, enhances profitability, and ensures an exceptional player experience.'}
            />
          </Col>
          <Col lg={{ span: 4, offset: 4 }} md="6" className="text-center mb-4">
            <IconCard
                image={PAPCLogoPng}
                imageLink="/papc"
                desc={'The Patrik Antonius Poker Challenge - Live poker festivals combining fun and competitive play, with a wide range of game types and buy-ins to suit all players'}
            />
          </Col>
          <Col sm="6" md="5" lg="4" className="mt-3 " />
        </Row>
      </Box>
    </Section>
  );
};

export default Products;
