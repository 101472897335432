import React, { Fragment } from "react";
import styled from "styled-components";
import { Link, useIntl } from "gatsby-plugin-intl";

import { Title, Box, Text, Span } from "../Core";
import { device } from "../../utils";

const Card = styled(Box)`
  border-radius: 10px 10px;
  border: 1px solid #eae9f2;
  transition: 0.4s;
  overflow: hidden;

  &:hover {
    box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  }
`;

const ImageContainerHorizontal = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${device.md} {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
  @media ${device.lg} {
    min-width: 265px;
  }
  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`;

const BrandImage = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 8px;
  border: 1px solid #eae9f2;
  overflow: hidden;
`;

const CardText = styled(Box)`
  padding: 30px;
`;

const TitleStyled = styled(Title)`
  transition: 0.3s;
  height: 56px;
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const TextContentStyled = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
}`;

/** THIS COMPONENT WAS TAKEN FROM OMEGA POSTCARD COMPONENT */
const FlopPostCard = ({
  horizontal = false,
  img,
  imgBrand,
  imgHeight,
  link,
  preTitle,
  title,
  children,
  readMore,
  ...rest
}) => {
  const intl = useIntl();
  const ConditionnalLinkWrapper = link ? Link : Fragment;
  return (
    <Card
      className={
        horizontal
          ? "d-flex flex-column flex-md-row"
          : "d-flex flex-column h-100"
      }
      {...rest}
    >
      {horizontal ? (
        <ImageContainerHorizontal>
          <ConditionnalLinkWrapper to={link} className="w-100 h-100 d-flex">
            <img
              src={img}
              alt="First land of poker"
              className="w-100 img-fluid"
            />
            {imgBrand && (
              <BrandImage>
                <img
                  src={imgBrand}
                  alt="First land of poker brand"
                  className="img-fluid"
                  height={70}
                />
              </BrandImage>
            )}
          </ConditionnalLinkWrapper>
        </ImageContainerHorizontal>
      ) : (
        <Box height={imgHeight} className="overflow-hidden position-relative">
          <ConditionnalLinkWrapper to={link} className="w-100 d-flex">
            <img
              src={img}
              alt="First land of poker"
              className="w-100 img-fluid"
            />
            {imgBrand && (
              <BrandImage>
                <img
                  src={imgBrand}
                  alt="First land of poker brand"
                  style={{height: 30}}
                />
              </BrandImage>
            )}
          </ConditionnalLinkWrapper>
        </Box>
      )}

      <CardText className="d-flex flex-column flex-grow-1">
        {preTitle && (
          <Text fontSize={2} lineHeight={1.75} mb="14px">
            {preTitle}
          </Text>
        )}

        <ConditionnalLinkWrapper to={link}>
          <TitleStyled variant="card" mb="12">
            {title}
          </TitleStyled>
        </ConditionnalLinkWrapper>
        {children && (
            <TextContentStyled fontSize={2} lineHeight={1.75} mb="16px">
              {children}
            </TextContentStyled>
        )}
        {readMore && (
          <Box alignItems="flex-end" display="flex" flex="1">
            <ConditionnalLinkWrapper to={link}>
              <Span color="primary">
                {intl.formatMessage({ id: "blog.post.read_more" })}
              </Span>
            </ConditionnalLinkWrapper>
          </Box>
        )}
      </CardText>
    </Card>
  );
};

export default FlopPostCard;
