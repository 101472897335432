import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import FlopPostCard from "../../components/Flop/FlopPostCard";
import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import patrikPng from "../../assets/image/flop/team/patrik-full.png";
import idrissPng from "../../assets/image/flop/team/idriss-full.png";
import patrikNamePng from "../../assets/image/flop/team/patrik-name.png";
import idrissNamePng from "../../assets/image/flop/team/idriss-name.png";

const ULStyled = styled.div`
  ul {
    list-style: disc !important;
  }
  li {
    text-align: left;
  }
`;

const FoundingTeam = () => {
  return (
    <>
      {/* <!-- Team section --> */}
      <Section bg="greyBg" py={5} className="position-relative">
        <Box px={[1, 1, 1, 5, 6]}>
          <Row>
            <Col lg="6" className="text-left pb-3">
              <div className="">
                <Title>
                  <FlopFormattedMessageWrapper id="about.founding.team.title" />
                </Title>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xl="4" lg="4" md="6" sm="7" className="mb-4 mx-lg-4">
              <FlopPostCard
                img={idrissPng}
                imgBrand={idrissNamePng}
                title={
                  'Co-founder & CEO'
                }
              >
                <Text variant={'small'}>
                  Idriss brings over a decade of expertise from the tech industry. An engineer specializing in applied mathematics and data science, Idriss has a proven track record of harnessing technology and data-driven insights to drive innovation. His passion for poker and his technical prowess forms the foundation of our cutting-edge digital solutions.
                </Text>
              </FlopPostCard>
            </Col>
            <Col xl="4" lg="4" md="6" sm="7" className="mb-4 mx-lg-4">
              <FlopPostCard
                  img={patrikPng}
                  imgBrand={patrikNamePng}
                  title={
                    'Co-founder & Brand Ambassador'
                  }
              >
                <Text variant={'small'}>
                  Patrik is a legend in the poker world. With an illustrious career spanning over 20 years, Patrik is one of the most respected figures in the poker industry. His insights from years of professional play inform our commitment to enhancing the poker experience for players and operators alike.
                </Text>
              </FlopPostCard>
            </Col>
          </Row>
        </Box>
      </Section>
    </>
  );
};
export default FoundingTeam;
