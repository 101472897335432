import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Box, Section, Text, Title } from "../components/Core";
import FoundingTeam from "../sections/flop-about-us/FoundingTeam";
import FlopAppSection from "../sections/flop-about-us/FlopAppSection";
import Products from "../sections/flop-about-us/Products";

import flopProductsPng from "../assets/image/flop/flop-products.png";
import PAPCLogoPng from "../assets/image/flop/PAPC_logo.png";
import Seo from "../components/Seo/seo";

const AboutUsPage = () => {
  const intl = useIntl();
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Seo
          title="About us"
          desc="Get more informations about the Team and their history."
        />
        <Section bg="black">
          <Box px={[2, 2, 6, 6]} pt="5">
            <Row className="justify-content-center align-items-center">
              <Col lg="6">
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title color="light" variant="hero">
                    Our Mission.
                  </Title>
                  <Text color="light" mb={4}>
                    Our mission at First Land of Poker is to revolutionize the world of poker through innovative digital solutions, enhancing the experience for players and streamlining operations for poker room operators.
                  </Text>
                </Box>
              </Col>
              <Col xs="12" lg="6">
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]} className={'text-center'}>
                  <img
                    alt="Flop team, and more"
                    className="img-fluid mb-5"
                    src={flopProductsPng}
                  />
                  <img
                      alt="Flop team, and more"
                      className="img-fluid"
                      src={PAPCLogoPng}
                  />
                  <Text color="light" variant={'small'}>
                    The Patrik Antonius Poker Challenge - A live poker festival brand, powered by FLOP.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Box>
        </Section>
        <Products />
        <FoundingTeam />
        <FlopAppSection />
      </PageWrapper>
    </>
  );
};
export default AboutUsPage;
