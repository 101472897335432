import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text, Button } from "../../components/Core";

import FlopFormattedMessageWrapper from "../../components/Flop/FlopFormattedMessageWrapper";

import androidIcon from "../../assets/image/flop/products/flop-app/icon-android.png";
import iosIcon from "../../assets/image/flop/products/flop-app/icon-ios.png";
import flopApp from "../../assets/image/flop/flop-app.svg";
import { Link } from "gatsby-plugin-intl";

const FlopAppSection = () => {
  return (
    <Section bg="black" py={4}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="5" className="text-center mb-2">
            <Link to="/flop-app">
              <img src={flopApp} alt="Flop App Logo"></img>
            </Link>
          </Col>
          <Col lg="12" className="text-center">
            <Title color="light" className="mb-4">
              You Digital Companion for Live Poker Excellence
            </Title>
          </Col>
          <Col lg="8" className="text-center mb-5">
            <Text color="light">
              Become part of the exclusive FLOP community, connect with other players, connect with poker rooms, and find the right game for you
            </Text>
          </Col>
          <Col lg="6" className="text-center">
            <a href="https://flop.page.link/app">
              <Button mb={4} bg="#28AFAC" borderRadius={28}>
                <FlopFormattedMessageWrapper id="about.flopapp.cta" />
              </Button>
            </a>
            <Box>
              <Text fontWeight={200} fontSize={"17px"} color="light">
                <FlopFormattedMessageWrapper id="about.flopapp.download" />
                <img
                  src={androidIcon}
                  className="mx-2"
                  alt="Andoid Icon for FLOP download"
                ></img>
                &amp;
                <img
                  src={iosIcon}
                  className="mx-2"
                  alt="iOs icon for FLOP download"
                ></img>
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default FlopAppSection;
