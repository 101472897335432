import { Link } from "gatsby-plugin-intl";
import React, { Fragment } from "react";

import { Box, Text } from "../../components/Core";

// Component extracted from landing 07 of Omega
const IconCard = ({
  className,
  image,
  imageLink,
  name,
  desc,
  children,
  ...rest
}) => {
  // Add link wrapper in order to manage the link only if it's provided
  const ConditionnalLinkWrapper = imageLink ? Link : Fragment;

  return (
    <Box
      borderColor="border"
      borderRadius={10}
      className={`d-flex flex-column ${className}`}
      {...rest}
      data-aos="fade-down"
      data-aos-delay="500"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <Box className={`mt-auto`}>
        <Box className="text-center">
          <ConditionnalLinkWrapper to={imageLink}>
            <img
              src={image}
              alt="First land of poker icon card"
              style={{height: 80}}
            />
          </ConditionnalLinkWrapper>
        </Box>
        <Box className="flex-grow-1 text-center" mt={3}>
          <Text variant="small" opacity={0.7}>
            {desc}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default IconCard;
